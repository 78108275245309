import { BoxProps } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import React from 'react'
import { useIntl } from 'react-intl'

import { MicrosoftLogo } from 'components/Illustration'
import m from 'pages/Login/messages'

function MicrosoftLoginButton(props: BoxProps) {
  const { formatMessage: f } = useIntl()

  return (
    <Box {...props}>
      <form acceptCharset="UTF-8" action="/auth/azure_ad" method="POST">
        <Button
          fullWidth
          startIcon={
            <MicrosoftLogo
              style={{ left: 8, position: 'absolute', top: 8, width: '1em' }}
            />
          }
          type="submit"
        >
          {f(m.signInWithMicrosoft)}
        </Button>
      </form>
    </Box>
  )
}

export default MicrosoftLoginButton
