import { SxProps, Theme } from '@mui/material'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'

import PasswordField from 'components/PasswordField'

import MicrosoftLoginButton from './MicrosoftLoginButton'

const flashError = window.flash?.error
const flashSuccess = window.flash?.success

type Props = {
  sx: SxProps<Theme>
}

function Form({ sx }: Props) {
  const [loading, setLoading] = useState(false)
  const forgotPasswordUrl = `/p/forgot_password?locale=${window.locale_name}`

  const errorMessage = flashError
    ? flashError === 'Invalid email or password'
      ? window.T('Email address or password was incorrect. Please try again.')
      : flashError
    : null

  const handleSubmit = () => {
    setLoading(true)
  }

  return (
    <Grid container direction="column" sx={sx}>
      <Typography
        className={window.CONST.azureAdSsoLoginEnabled ? '-mb6' : '-mb2'}
        variant="h1"
      >
        {window.T('Log in to your account')}
      </Typography>

      {!window.CONST.azureAdSsoLoginEnabled ? (
        <Typography className="-mb6" color="textSecondary">
          {window.T(
            'Enter your account email address and password to log in to the system.'
          )}
        </Typography>
      ) : null}

      {flashSuccess && (
        <Alert
          className="-mb6"
          data-test-id="page.login.successMessage"
          severity="success"
        >
          {flashSuccess}
        </Alert>
      )}

      {errorMessage && (
        <Alert
          className="-mb6"
          data-test-id="page.login.errorMessage"
          severity="error"
        >
          {errorMessage}
        </Alert>
      )}

      <form
        acceptCharset="UTF-8"
        action="/sessions"
        method="post"
        onSubmit={handleSubmit}
      >
        <TextField
          autoComplete="email"
          autoFocus
          className="-mb6"
          disabled={loading}
          fullWidth
          inputProps={{
            'data-test-id': 'page.login.email',
            disabled: false,
            readOnly: loading,
          }}
          label={window.T('app.base.email')}
          name="user_session[email]"
        />

        <PasswordField
          className="-mb6"
          disabled={loading}
          fullWidth
          inputProps={{
            autoComplete: 'current-password',
            'data-test-id': 'page.login.password',
            disabled: false,
            readOnly: loading,
          }}
          label={window.T('app.base.password')}
          name="user_session[password]"
          showStatus={false}
        />

        <Grid container justifyContent="space-between">
          <Button
            color="primary"
            data-test-id="page.login.logIn"
            disabled={loading}
            type="submit"
            variant="contained"
          >
            {window.T('Log in')}

            {loading && (
              <CircularProgress
                data-test-id="page.login.logIn.spinner"
                size={16}
              />
            )}
          </Button>

          <Button
            component="a"
            data-test-id="page.login.forgotPassword"
            disabled={loading}
            href={forgotPasswordUrl}
            variant="text"
          >
            {window.T('Forgot password?')}
          </Button>
        </Grid>
      </form>

      {window.CONST.azureAdSsoLoginEnabled ? (
        <>
          <Typography
            align="center"
            color="textSecondary"
            sx={{
              '& span': {
                backgroundColor: '#fff',
                left: '50%',
                mt: '-11px',
                padding: '0 8px',
                position: 'absolute',
                top: 0,
                transform: 'translateX(-50%)',
              },
              borderBottom: '1px solid #607d8b',
              borderBottomColor: 'rgba(96,125,139,0.34)',
              mb: 10,
              mt: 10,
              position: 'relative',
            }}
          >
            <span>{window.T('app.base.or')}</span>
          </Typography>

          <MicrosoftLoginButton sx={{ mb: 6 }} />
        </>
      ) : null}
    </Grid>
  )
}

export default Form
